@import "../var";

.layout {
  --sectionPadding: 50px;
  background-color: $white;
  @include max($md) {
    --sectionPadding: 30px;
  }
  .expireStore_wrap {
    height: calc(100vh - 82px);
    overflow-y: auto;
    @include flexCenter;
  }
}
