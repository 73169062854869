@import "./var";

:root {
  @include cssVariables;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;

  @include max($md) {
    font-size: 14px;
  }
}

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;

  &:not(svg *) {
    color: $primary;
  }
}

img {
  max-width: 100%;
  -webkit-user-drag: none;
  -webkit-user-drag: none;
}

h1 {
  font-size: var(--f48);
  font-weight: 700;
}

h2 {
  font-size: var(--f32);
  font-weight: 700;
  word-wrap: break-word;
}

h3 {
  font-size: var(--f24);
  font-weight: 700;
}

h4 {
  font-size: var(--f20);
  font-weight: 600;
}

h5 {
  font-size: var(--f16);
  font-weight: 400;
}

h6 {
  font-size: var(--f14);
  font-weight: 400;
}

// ========== button styling ==============
.MuiButtonBase-root {
  &.Mui-disabled {
    color: $white !important;
    opacity: 0.7;
  }
}

button.btn {
  box-shadow: none;
  min-height: 40px;
  border-radius: 8px;
  text-transform: none;
  font-size: 1rem;
  padding: 8px 18px;
  transition: 0.125s ease-in-out;
  color: $white;

  &:hover {
    box-shadow: none;
  }

  &.btn_primary {
    background-color: $btn_bg;

    &:hover {
      filter: brightness(0.985);
    }
  }

  &.btn_text_primary {
    width: auto;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  &.btn_outlined_primary {
    background-color: #fff;
    color: $primary;
    border-color: $primary;
    // width: auto;

    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
    }
  }

  &.btnicon {
    padding: 8px;
    min-width: 0;
    min-height: 0;
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1.25rem;

    svg {
      width: 0.75em;
      height: auto;
    }
  }
  &.btn_success {
    background-color: $green;
    color: $white;
    &:hover {
      filter: brightness(0.985);
    }
  }
  &.Mui-disabled {
    color: $white;
    opacity: 0.7;
  }
}

// ========= heading ====================
.section_heading {
  margin-bottom: 1rem;

  &.center {
    text-align: center;
  }

  h4 {
    margin-bottom: 10px;
  }

  p {
    line-height: 1.4;
    font-weight: 500;

    b {
      font-weight: 600;
    }
  }
}

// ====== mui form styling =================
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
label {
  @include noUserSelect;
}
label.form_label {
  font-size: var(--f14);
  font-weight: 500;
  margin-bottom: 6px;
  color: inherit;
  line-height: 1.2;
  display: block;

  sup {
    color: $error;
    position: absolute;
    top: 0;
    line-height: 1;
  }
}
.form_control {
  position: relative;

  .form_input_wrap {
    position: relative;
    // margin-bottom: 14px;
  }

  .form_input_wrap ::placeholder {
    color: #888889;
    opacity: 1; /* Firefox */
  }

  .form_input_wrap :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #888889;
  }

  .form_input_wrap ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #888889;
  }

  .input_prefix {
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 2;
  }
  .form_input_icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .url_field_wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    .url_input {
      flex-grow: 1;
    }
  }

  .form_input,
  .form_select {
    width: 100%;

    .MuiInputBase-formControl {
      background: #fff;
      color: inherit;
      border-radius: 8px;
    }
    .MuiAutocomplete-inputRoot {
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiOutlinedInput-input {
      height: 3rem;
      border-radius: inherit;
      // height: 100%;
      box-sizing: inherit;
      box-shadow: none;
      color: $primary;
      font-size: 1rem;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      &:not(.MuiInputBase-inputMultiline, .MuiAutocomplete-input) {
        padding: 12px 14px;
      }
    }
    .MuiInputBase-multiline {
      padding: 0;
      textarea {
        padding: 12px 14px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary;
      border-radius: 8px;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }

      &:not(.Mui-error) {
        .MuiOutlinedInput-notchedOutline {
          border-color: $black;
        }
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
      &:not(.Mui-error) {
        .MuiOutlinedInput-notchedOutline {
          border-color: $black;
        }
      }
    }

    &.search_field {
      .MuiOutlinedInput-input {
        padding-left: 2.6rem;
        font-size: inherit;
      }
    }
    &.hasPrefix {
      .MuiOutlinedInput-input {
        padding-left: 2rem;
      }
    }
    &.type_password {
      .MuiOutlinedInput-input {
        padding-right: 2.5rem;
      }
    }

    &.placeholderSelected {
      .MuiOutlinedInput-input {
        color: #898888;
        opacity: 0.8;
      }
    }
  }
  .add_select_wrap {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;

    .addBtn {
      min-width: 0;
      padding: 0;
      cursor: pointer;
      height: 1.5rem;
      width: 1.5rem;
      @include flexCenter;
      border: 1px solid $primary;
      border-radius: 4px;
      flex-shrink: 0;

      svg {
        width: 95%;
        height: auto;
      }
    }

    .add_select_menu {
      padding: 12px;
      background-color: #fff;
      z-index: 2;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 8px;
      margin-top: 8px;
      max-height: 180px;
      overflow-y: auto;
      .noData {
        padding: 6px 0;
        text-align: center;
      }
      input {
        border: 1px solid $primary;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        width: 100%;
        padding: 4px 6px;
        font-size: 14px;
        min-height: 32px;
        margin-bottom: 10px;
        outline: none;

        &::placeholder {
          opacity: 0.6;
        }
      }
      .add_select_menuList {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow-y: auto;
        gap: 10px;
        .listItem {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 14px;
        }
        .listTitle {
          flex-grow: 1;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 4px;
          padding: 8px;
        }
        .removeIcon {
          min-height: 0;
          min-width: 0;
          height: 1.5rem;
          width: 1.5rem;
          padding: 4px;
          svg {
            width: 0.85em;
          }
        }
      }
    }
  }

  .searchIcon {
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    svg {
      width: 1.25em;
      height: auto;
    }
  }

  .form_radio {
    padding-top: 4px;
    gap: 1rem 1.875rem;

    .MuiFormControlLabel-root {
      margin: 0;
    }

    .MuiRadio-root {
      padding: 0px;

      &:not(.Mui-checked) {
        svg {
          color: $btn_bg;
        }
      }
    }

    .MuiSvgIcon-root {
      height: 1.25rem;
      width: 1.25rem;
    }

    .MuiFormControlLabel-label {
      padding-left: 8px;
    }
  }

  .error {
    position: absolute;
    top: calc(100% + 2px);
    right: 4px;
    color: $error;
    font-size: 12px;
    font-weight: 400;
  }
}

.multi_checkbox_list {
  border: 1px solid $primary;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: none;
}
.form_select_list {
  z-index: 9999;
  .MuiPaper-root {
    border: 1px solid $primary;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: none;
  }
  .MuiList-root {
    padding: 5px 0;
  }
  .MuiButtonBase-root {
    background-color: transparent;
    padding: 6px 10px;
    font-size: 14px;
  }
  .checkboxList_item {
    padding: 6px 14px;
    font-size: var(--f14);
    cursor: pointer;
    .MuiCheckbox-root {
      padding: 0;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

// ========= static inputs and select ==========
.static_selectField {
  cursor: pointer;
  flex-grow: 1;
  border: 1px solid $primary;
  border-radius: 8px;
  background-color: $white;
  padding: 12px 14px;
  position: relative;
  &.placeholder {
    color: rgba($primary, 0.6);
  }
  .selectArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

.static_input {
  height: 3rem;
  padding: 12px 14px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;

  b {
    font-weight: 700;
  }
}

// ===============checkbox button==============
.checkbox_btn {
  span {
    @include btn;
    border-color: $primary;
    background-color: transparent;

    svg {
      width: 1.2em;
      height: 1.2em;
      display: none;
    }
  }
  input {
    display: none;
    &:checked {
      & ~ span {
        border-color: transparent;
        background-color: $btn_bg;
        svg {
          display: inline-block;
        }
      }
    }
  }
}
// ===============radio button==============
.radio_btn {
  span {
    @include btn;
    border-color: transparent;
    background-color: $btn_bg;
  }
  input {
    display: none;
    &:checked {
      & ~ span {
        border-color: $primary;
        background-color: transparent;
      }
    }
  }
}

// ============= form wrapper ==================
.fields_wrap {
  --spaceX: 15px;
  --spaceY: 20px;

  &.space10 {
    --spaceX: 10px;
    --spaceY: 24px;
  }

  &.MuiGrid-container {
    width: auto;
    row-gap: var(--spaceY);
    margin-left: calc(-1 * var(--spaceX));
    margin-right: calc(-1 * var(--spaceX));

    > * {
      padding: 0 var(--spaceX);
    }
  }

  &.mb_grid {
    margin-bottom: 1.25rem;
  }

  @include max($md) {
    --spaceY: 14px;
  }
}

// ============= Switch label ==================
label.switch_formLabel {
  margin: 0;
}

// ============= Theme table ==================
.theme_table {
  border: 1px solid $primary;
  overflow-x: auto;
  border-radius: 8px;
  --thead_padding: 0.875rem 1rem;
  --tbody_padding: 1.5rem 1rem;
  .containedWidth {
    width: 1%;
  }

  table {
    white-space: nowrap;

    .cell_dateTime {
      font-weight: 400;
      white-space: nowrap;
      p {
        font-size: var(--f14);
        margin-bottom: 2px;
      }

      span {
        font-size: 12px;
      }
    }

    th,
    td {
      color: $primary;
      border-bottom: 1px solid $primary;
      line-height: 1.25;
    }
  }

  thead {
    th,
    td {
      padding: var(--thead_padding);
    }

    th {
      background-color: $tableHeadColor;

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  tbody {
    th,
    td {
      padding: var(--tbody_padding);
      background-color: #ffffff;

      &:first-child {
        border-radius: 0 0 0 8px;
      }

      &:last-child {
        border-radius: 0 0 8px 0;
      }
    }

    tr {
      &:last-child {
        > * {
          border: none;
        }
      }
    }
  }
}
// ============== Theme Tabs ===================
.theme_tabs {
  border-bottom: 1px solid $borderColor;
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
  .MuiTabs-root {
    min-height: 0;
  }

  .MuiTabs-flexContainer {
    gap: 1.5rem;
  }

  .MuiButtonBase-root {
    text-transform: none;
    padding: 10px 4px;
    min-height: 0;
    min-width: 0;
    flex-direction: row;
    gap: 0.5rem;
    svg {
      margin: 0;
      width: 1.4em;
      height: auto;
    }
  }
}

// ================= theme accordion =============
.theme_accordion {
  --accSidePadding: 1.875rem;
  --accSidePadding: 1.875rem;

  h4 {
    font-size: var(--f18);
    font-weight: 500;
  }

  .MuiAccordion-root {
    &.MuiPaper-root {
      box-shadow: none;
      border: 1px solid $primary;
      min-height: auto;
      margin-bottom: 1.25rem !important;
      border-radius: 8px;
      overflow: hidden;

      &.Mui-expanded {
        margin: 0;
      }
    }
  }

  .MuiAccordionSummary-root {
    min-height: 3.5rem;
    padding: 10px var(--accSidePadding);

    &.Mui-expanded {
      min-height: 3.5rem;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 1;
    margin: 0;

    .checkIcon {
      line-height: 0;

      svg {
        color: #e9e9e9;
        width: 20px;
        height: auto;
      }

      &.active {
        svg {
          color: $primary;
        }
      }
    }

    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiCollapse-root {
    border-top: 1px solid $primary;
  }

  .MuiAccordionDetails-root {
    padding: 1.875rem var(--accSidePadding);
  }
}

// =========== Menu styling =================
.menuList {
  padding: 0;
  .MuiPaper-root {
    border: 1px solid $primary;
    border-radius: 8px;
    transform: translate(45px, -40px) !important;
    box-shadow: none;
    overflow: visible;
    .MuiButtonBase-root {
      font-weight: 500;
      font-size: var(--f14);
      padding: 6px 12px;
      min-width: 150px;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
  &:not(.noArrow) {
    .MuiPaper-root {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 16px;
        right: calc(100% - 7px);
        height: 14px;
        width: 14px;
        border-radius: 0 4px 0 0;
        border-top: 1px solid $primary;
        border-right: 1px solid $primary;
        transform: rotate(-135deg);
        background-color: #fff;
        overflow: visible;
        z-index: -1;
      }
    }
  }

  &.left {
    .MuiPaper-root {
      transform: translate(calc(-100% - 15px), -40px) !important;
      &::after {
        right: auto;
        left: calc(100% - 7px);
        transform: rotate(45deg);
      }
    }
  }
  &.bottom {
    .MuiPaper-root {
      transform: translate(-11%, 5px) !important;
      &::after {
        right: auto;
        left: calc(100% - 7px);
        transform: rotate(45deg);
      }
    }
  }
}

.custom_loader {
  display: flex;
  align-items: center;
  height: 100vh;
}

.custom_loader svg {
  width: 5%;
}

.tokenInvalid {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  margin: 0px;
  border-radius: 0px;
  background-color: #f9f9f9;
}
.custom_lineBreak {
  height: 200px;
}
.Custom_button {
  background: #e9e9e9 !important;
  width: 220px;
  height: 47px;
  border-radius: 8px !important;
}
.custom_inputCrose {
  position: relative;
}
.clear_btns {
  position: absolute;
  top: 50%;
}
.Search_btn {
  @include max($md) {
    width: 100px !important;
  }
}
