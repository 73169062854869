@import "../var";

button {
  cursor: pointer;
}

// ============= header section =================
.logo {
  img {
    max-height: 60px;
    max-width: 150px;
  }

  @include max($md) {
    max-height: 50px;
    max-width: 130px;
  }
}

.header {
  position: sticky;
  top: 0;
  background: $white;
  z-index: 999;

  .header_inner {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 4px;
      font-size: 1.5em;
    }

    .cartIcon {
      button {
        height: 2.25rem;
        width: 2.25rem;
        min-width: 0;
        min-height: 0;
        border-color: $primary;
      }

      :global {
        .MuiBadge-badge {
          background-color: $primary;
        }
      }
    }
  }

  &.headerOnly {
    border-bottom: 1px solid $borderColor;
  }

  .storeName {
    font-size: 20px;
    font-weight: 500;
    padding: 0 20px;
    text-align: center;
    margin-left: -137px;

    @include max($md) {
      display: none;
    }
  }
}

// ============= banner section =================
.banner {
  background-color: #e9e9e9;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  // min-height: 778px;
  .watermark {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100px;
    // mix-blend-mode: overlay;
    max-width: 45%;
  }

  .banner_image {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
    margin-bottom: -3px;
  }
}

// ============= timer section =================
.timer_section {
  padding: var(--sectionPadding) 0;

  @include min($lg) {
    padding: 0 0 var(--sectionPadding);
  }

  :global {
    .heading {
      text-align: left;
      flex-grow: 1;
    }
  }

  .content_box {
    background-color: $white;
    max-width: 1030px;
    margin: 0 auto;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 2rem;

    @include min($lg) {
      position: relative;
      z-index: 2;
      min-height: 200px;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04);
      padding: 1rem 2rem;
      margin-top: -70px;
      gap: 2rem;
    }

    @include max($lg) {
      justify-content: center;
      flex-wrap: wrap;

      :global(.heading) {
        flex-basis: 100%;
        text-align: center;
      }
    }
  }

  .timer_box {
    flex-shrink: 0;
    border-radius: 8px;
    background-color: rgba($btn_bg, 0.1);
    padding: 1rem 1rem;

    @include min($lg) {
      width: 295px;
    }

    @include max($sm) {
      width: 100%;
    }

    p {
      margin: 12px 0 16px;
      font-size: var(--f18);
    }

    .dateBtn {
      padding: 6px 10px;
      font-size: var(--f14);
      font-weight: 600;
      background-color: #ff0000;
      border-radius: 8px;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
    }

    .timeBox_wrapper {
      display: flex;
      gap: 10px;
    }

    .timeBox {
      flex-basis: 33.33%;
      border-radius: 8px;
      border: 1px solid $primary;
      text-align: center;
      padding: 10px 8px;

      h2 {
        font-size: var(--f36);
        margin-bottom: 3px;
      }

      h5 {
        font-size: var(--f20);
      }
    }
  }
}

.errorMsg {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

// ============ image section ============
.imageBox_section {
  padding: var(--sectionPadding) 0;
  --bgHeight: 570px;
  --imageSpace: 80px;

  @include max($lg) {
    --bgHeight: 360px;
    --imageSpace: 40px;
  }

  .bg_image {
    padding: var(--imageSpace) 1rem;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: var(--bgHeight);
    background-color: red;
    @include flexCenter;
  }

  .whiteBox {
    max-width: 1020px;
    width: 90%;
    background-color: rgba($white, 0.95);
    padding: 20px;
    border-radius: inherit;

    .whiteBox_border {
      padding: inherit;
      min-height: calc(var(--bgHeight) - (2.5 * var(--imageSpace)));
      border: 1px solid $primary;
      border-radius: inherit;
      @include flexCenter;
    }

    :global(.heading) {
      margin-bottom: 0;
      width: 80%;

      p {
        margin-top: 1rem;
        line-height: 1.6;
      }
    }

    @include max($md) {
      width: 95%;
      padding: 12px;

      :global(.heading) {
        width: 100%;
      }
    }
  }
}

// ======== contact section ===================

.contact_section {
  padding: var(--sectionPadding) 0;

  .contact_section_inner {
    padding-top: var(--sectionPadding);
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba($btn_bg, 0.1);
      height: calc(100% - (1.5 * var(--sectionPadding)));
      z-index: -1;

      @include max($md) {
        height: calc(100% - (-0.5 * var(--sectionPadding)));
      }
    }
  }

  .contCard_wrapper {
    --spaceX: 15px;
    --spaceY: 20px;
    --colWidth: 33.33%;
    // margin: 0 calc(-1 * var(--spaceX));
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: var(--spaceY) 0;

    .contact_card_col {
      padding: 0 var(--spaceX);
      flex-basis: var(--colWidth);
      max-width: var(--colWidth);
    }

    // @include max($lg) {
    //   --colWidth: 50%;
    // }
    @include max($md) {
      --colWidth: 100%;
    }
  }

  .contact_card {
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 2.25rem 2rem;
    height: 375px;
    position: relative;

    .cardContent {
      margin-top: 20px;

      h4 {
        font-size: var(--f18);
        font-weight: 500;
      }

      h3 {
        font-size: 16px;
        margin: 7px 0px;
      }

      .line {
        border-bottom: 1px solid $primary;
        width: 90px;
        margin: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        position: absolute;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        word-break: break-word;
      }
    }
  }
}

// ======== contact us section ===================
.contactUs_section {
  padding: var(--sectionPadding) 0 calc(1.8 * var(--sectionPadding));

  .heading {
    margin-bottom: 2.5rem;
  }

  .visitLinks {
    display: flex;
    justify-content: center;
    text-align: center;

    h4 {
      font-size: var(--f18);
      margin-bottom: 10px;
      font-weight: 400;
    }

    .singleLink {
      padding: 0 1.875rem;

      &:not(:last-child) {
        border-right: 1px solid $borderColor;
      }

      svg {
        max-width: 40px;
        max-height: 40px;
      }

      @include max($md) {
        h4 {
          font-size: var(--f14);
        }

        svg {
          max-width: 42px;
          max-height: 42px;
        }
      }
    }
  }
}

// ============= footer section =====================

.footer_section {
  border-top: 1px solid $btn_bg;

  .footer_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    min-height: 3.75rem;

    * {
      color: inherit;
      // @include max($lg) {
      //   display: flex;
      //   justify-content: center;
      // }
    }

    .logo {
      @include max($lg) {
        display: flex;
        justify-content: center;
      }
    }

    .logo,
    .copyright {
      @include min($lg) {
        flex-basis: 240px;
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
      justify-content: center;
    }

    .footerLinks {
      display: flex;
      font-size: var(--f14);

      a {
        padding: 0 12px;
        text-decoration: none;

        &:not(:last-child) {
          // border-right: 1px solid $primary;
        }
      }

      @include max($lg) {
        justify-content: center;
      }
    }

    @include max($lg) {
      gap: 20px;
      justify-content: center !important;

      >* {
        flex-basis: 100%;
      }

      .copyright {
        justify-content: center !important;
      }
    }
  }
}

.headingBanner {
  font-size: 92px;
  font-weight: 800;
  color: #cccccc;
  margin-left: 20px;
  padding-top: 20px;

  @media (max-width: 1368px) {
    font-size: 36px;
  }

  @media (max-width: 992px) {
    font-size: 36px;
  }
}

.bannerSubText {
  font-weight: 800;
  font-size: 32px;
  color: #cccccc;
  margin-right: 10px;

  @media (max-width: 992px) {
    font-size: 20px;
  }
}

.headingContent {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-top: 30px;

  @include max($sm) {
    margin-left: 0px;
  }
}

.bannerImg {
  width: unset !important;
  min-height: unset !important;
  height: 27px;
}

// bandle section

.banner_bundle {
  background-color: $btn_bg;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 372px;
  height: 40vh;

  img {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }

  span {
    color: $primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    @include max($sm) {
      font-size: 13px;
    }
  }

  .bannerImg {
    width: 21px !important;
    height: 20px;
    min-height: unset !important;
    margin-right: 15px;

    @include max($sm) {
      width: 18px !important;
      height: 18px;
      margin-right: 8px;
    }
  }
}

.bundle_listMain {
  padding: 20px 0px;

  @include max($lg) {
    padding: 10px 0px;
  }

  .bundle_list {
    background: rgba($btn_bg, 0.1);
    border-radius: 8px;
    padding: 10px 10px 10px 30px;

    .bundle_listCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 769px) {
        flex-direction: column;
      }

      .bundle_listCardContent {
        padding-right: 30px;

        h5 {
          font-weight: 800;
          font-size: 20px;
          line-height: 26px;
          color: $primary;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: $primary;
          margin: 5px 0px 15px 0px;
        }

        h3 {
          font-weight: 800;
          font-size: 32px;
          line-height: 26px;
          color: $primary;
        }
      }

      .bundle_listCardlist {
        display: flex;
        align-items: center;
        background: #ffffff;
        border-radius: 8px;
        justify-content: flex-end;
        flex: 1;
        padding: 10px 20px;

        @media (max-width: 769px) {
          flex-direction: column;
          margin: 20px 0px;
        }

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: $primary;
          margin: 10px 20px;
        }

        button {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          border: 1px solid #e9e9e9;
          font-weight: 500;
          font-size: 16px;
          // width: 180px;
          // height: 44px;
          // @media (max-width: 769px) {
          //   width: 100%;
          // }
        }
      }
    }
  }
}

.shoppingCart_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
    margin: 20px 10px;
  }

  .shoppingCart_img {
    max-height: 120px;
    --space: 4px;
    width: 130px;
    background: #f9f9f9;
    border: 1px solid $primary;
    border-radius: 6px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: calc(2 * var(--space)) 0;
    margin: calc(-1 * var(--space));
    overflow: auto;

    .img_wrap {
      padding: 0 var(--space);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;

      @media (max-width: 425px) {
        padding: 0;
      }

      img {
        border-radius: 8px;
      }

      &.multiImage {
        flex-basis: 50%;
        position: relative;

        .layer {
          position: absolute;
          left: var(--space);
          width: calc(100% - (2 * var(--space)));
          height: 100%;
          background-color: rgba($black, 0.6);
          color: $white;

          @media (max-width: 425px) {
            width: 100%;
            left: 0;
          }

          @include flexCenter;

          img {
            filter: brightness(0.5);
          }
        }

        img {
          // object-fit: cover;
          max-height: 44px;
          max-width: 44px;

          // width: 100%;
          // object-position: top;
        }
      }
    }

    img {
      max-width: 100px;
      max-height: 100px;
    }

    @include max($lg) {
      width: 150px;
      justify-content: center;
      // img {
      //   height: 20px;
      // }
    }
  }

  .shoppingChat_heading {
    margin-left: 30px;
    width: 100%;

    span {
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      color: $primary;

      @media (max-width: 375px) {
        font-size: 13px;
      }
    }

    p {
      color: $primary;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin: 0px 0px 20px;
    }

    .errorMsg {
      color: red;
      font-size: 12px;
      margin-top: 5px;
      text-align: right;
    }

    .productPTag {
      margin: 0;
    }

    .viewBundle {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;

      span {
        font-weight: inherit;
        font-size: inherit;
        text-decoration: underline;
      }

      &::after {
        content: ">";
        text-decoration: none;
      }
    }

    button {
      background: #ffffff;
      border: 1px solid $primary;
      border-radius: 4px;
      width: 100px;
      height: 30px;
      font-weight: 500;
      font-size: 13px;
      color: #ff0000;
    }
  }

  .shoppingChat_items {
    display: inline-grid;

    @media (max-width: 992px) {
      // margin-top: 20px;
      // width: 100%;
    }

    span {
      font-weight: 800;
      font-size: 16px;
      color: $primary;
    }

    .qtyBtn {
      background: #ffffff;
      border: 1px solid $primary;
      border-radius: 6px;
      color: $primary;
      font-weight: 400;
      font-size: 16px;
      width: 200px;
      height: 30px;
      cursor: auto;
      // margin-top: 10px;
    }
  }
}

.shoppingCart {
  margin-top: 30px;
  border: 1px solid $primary;
  border-radius: 8px;
  overflow: hidden;

  .shoppingChat_subtotal {
    border-top: 1px solid $primary;
    background: #f9f9f9;
    text-align: right;
    padding: 20px;
  }
}

.shoppingChat_checkoutBtn {
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    gap: 10px;
  }

  @include max($md) {
    display: block !important;
  }

  .empty {
    background: #ffffff !important;
    border: 1px solid $primary;
    border-radius: 8px;
    width: 167px;
    height: 44px;
    color: $primary;
    font-weight: 500;
    font-size: 16px;
    margin: 20px 0px;
    text-transform: capitalize;

    @include max($md) {
      display: none;
    }
  }

  .continue {
    background: $btn_bg !important;
    border-radius: 8px;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    width: 230px;
    height: 44px;
    // border: 1px solid $primary;
    margin: 20px 0px;
    text-transform: capitalize;

    @include max($md) {
      width: 100%;

      margin-bottom: 0px !important;
    }

    &.shoppingBtn {
      background: $primary !important;
      color: white;

      @include max($md) {
        width: 100%;
        // margin-top: 0px !important;
      }
    }
  }

  .rightWrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    @include max($md) {
      gap: 0px;
    }
  }
}

.emptyShopping {
  h4 {
    font-weight: 800;
    font-size: 30px;
    color: $primary;
    margin-top: 25px;

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }

  p {
    font-weight: 400;
    color: $primary;
    font-size: 18px;
    margin: 15px 0px 30px 0px;

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  button {
    background: #e9e9e9;
    border-radius: 8px;
    width: 180px;
    height: 44px;
    font-weight: 500;
    font-size: 16px;
    border: 0px;

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
}

.emptyShoppingImg {
  @media (max-width: 992px) {
    width: 150px;
  }
}

.itemModalBtnCencel {
  width: 112px;
  height: 44px;
  border: 1px solid $primary !important;
  border-radius: 8px !important;
  margin-right: 10px !important;
  color: $primary !important;

  @media (max-width: 992px) {
    width: 100% !important;
  }
}

.itemModalBtnClose {
  width: 112px;
  height: 44px;
  border: 1px solid $primary !important;
  border-radius: 8px !important;
  margin-right: 0px !important;
  color: $primary !important;

  @media (max-width: 992px) {
    width: 100% !important;
  }
}

.itemModalBtnSave {
  width: 160px;
  height: 44px;
  background: $btn_bg !important;
  border-radius: 8px !important;
  border: 0px;
  color: $white !important;

  @media (max-width: 992px) {
    width: 100% !important;
    margin-top: 10px !important;
  }

  @media (max-width: 425px) {
    margin-top: 0px !important;
  }
}

.itemModalCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #e6e8ed;

  .itemModalContent {
    display: flex;
    align-items: flex-start;

    .imgWrap {
      width: 80px;
      height: 80px;
      border: 1px solid;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 8px;
        max-width: 70px;
        max-height: 70px;
      }

      @media (max-width: 992px) {
        width: 40px;
        height: 40px;

        img {
          max-width: 35px;
          max-height: 35px;
        }
      }
    }

    .itemModalHeading {
      margin-left: 30px;

      @include max($md) {
        margin-left: 15px;
      }

      h5 {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        color: $primary;

        @media (max-width: 992px) {
          font-size: 14px;
        }
      }

      p {
        color: $primary;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        @media (max-width: 992px) {
          font-size: 12px;
        }
      }
    }
  }

  .itemModalPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    span {
      font-weight: 800;
      font-size: 16px;
      color: $primary;

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
}

.shoppingItems {
  @media (max-width: 992px) {
    max-width: 90% !important;
  }
}

.bundleModal_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .modalTitle {
    font-weight: 800;
  }

  // @media (max-width: 992px) {
  //   flex-direction: column !important;
  // }
}

.bundleModal_btns {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 425px) {
    align-items: center;
  }
}

.custom_incrementDecrement {
  width: 119px;
  border: 1px solid $primary;
  border-radius: 6px;
  margin-top: 5px;

  .input_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;

    button {
      border: 0px;
      background-color: transparent;
      color: $primary;
      font-size: 16px;
      font-weight: 500;
    }

    .form_control {
      border: 0px;
      width: 35px;
      margin: 0px 25px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }

    input:focus {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
  }

  &.incDecInputWrap {
    width: 200px;
    height: 30px;

    .input_group {
      padding: 0 20px;
    }
  }

  .incDecWrap {
    button {
      width: 0;
    }
  }
}

.storeNotFound {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  // height: 100vh;
  overflow-y: auto;
  justify-content: center;
  text-align: center;

  @media (max-width: 992px) {
    height: auto;
  }

  .storeNotFoundBtn {
    width: 180px;
    height: 44px;
    background: #e9e9e9 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 0px;
    font-weight: 500;
    font-size: 16px;
    color: $primary;
    text-transform: capitalize;
  }

  img {
    width: 247px;
    height: 247px;

    @media (max-width: 992px) {
      width: 130px;
      height: 130px;
    }
  }

  h4 {
    font-weight: 800;
    font-size: 32px;
    color: $primary;
    margin-top: 30px;
    padding-bottom: 15px;

    @media (max-width: 992px) {
      font-weight: 600;
      font-size: 24px;
      margin-top: 10px;
      padding-bottom: 5px;
    }
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $primary;
    position: relative;

    @media (max-width: 992px) {
      font-size: 16px;
    }

    &::after {
      content: "";
      width: 64px;
      position: absolute;
      height: 2px;
      background: $primary;
      top: 12px;
      bottom: 0;
      margin-left: 5px;

      @media (max-width: 992px) {
        top: 10px;
        width: 40px;
      }
    }

    &::before {
      content: "";
      width: 64px;
      position: absolute;
      height: 2px;
      background: #898888ed;
      top: 12px;
      bottom: 0;
      left: -69px;

      @media (max-width: 992px) {
        top: 10px;
        width: 40px;
        left: -46px;
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $primary;
    padding-top: 20px;

    @media (max-width: 992px) {
      font-size: 15px;
      padding-top: 5px;
    }
  }
}

.footerP {
  font-weight: 800;
  font-size: 16px;
  font-family: Avenir;
}

.footerspan {
  font-weight: 800;
  font-size: 12px;
  font-family: Avenir;
  line-height: 24;
}

.footerspan {
  img {
    height: 10.85px;
  }
}

.storeExpire {
  h4 {
    padding-bottom: 8px;
  }

  p {
    padding: 3px 0px 40px 0px;
  }
}

.statusWrap {
  :global {
    .MuiOutlinedInput-input {
      color: #000 !important;
    }
  }
}

.shoppingCart_container {
  @include max($md) {
    display: none !important;
  }
}

.shoppingCart_mob_view {
  button {
    background: #ffffff;
    border: 1px solid #0e2b3a;
    border-radius: 4px;
    width: 100px;
    height: 30px;
    font-weight: 500;
    font-size: 13px;
    color: #ff0000;
  }

  @include min($md) {
    display: none !important;
  }
}

.shoppingChat_checkoutBtn_mob_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty_mob_view {
  background: #ffffff !important;
  border: 1px solid #0e2b3a !important;
  border-radius: 8px !important;
  width: 167px;
  height: 44px;
  color: #0e2b3a !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin: 20px 0px !important;
  text-transform: capitalize !important;

  @include min($md) {
    display: none !important;
  }
}

// .productDetail_description {
//   @include max($md) {
//     text-align: center;
//   }
// }