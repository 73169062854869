@import "../var";

// button {
//   cursor: pointer;
// }
// .button {
//   cursor: pointer;
//   text-transform: capitalize;
// }
.subBundle {
  width: 90%;
  margin: auto;
  .subBundleHeader {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.saveButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  // border-radius: 8px;
  background: #e9e9e9 !important;
  border: 1px solid $primary;
  color: $primary;
  font-weight: 500;
  font-size: 16px;
  // width: 180px;
  height: 44px;
  text-transform: capitalize;
}
.travel_package {
  padding: 50px 0px;
  @media (max-width: 769px) {
    padding: 20px 0px;
  }
  .textCenter {
    text-align: center;
    margin: 40px 0px;
    button {
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      width: 370px;
      height: 44px;
      text-transform: capitalize;
      @media (max-width: 769px) {
        width: 100%;
      }
    }
  }
  .product_row {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 769px) {
      flex-direction: column;
    }
    .product_lg {
      width: 23%;
      margin: 20px 10px;
      @media (max-width: 768px) {
        margin: 20px 0px;
      }
      .button {
        background: #e9e9e9 !important;
        border-radius: 8px;
        width: 250px;
        height: 43px;
        border: 0px;
        color: $primary;
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
      }
      @media (max-width: 769px) {
        width: 100%;
      }
      .product_card {
        height: 100%;
        overflow: hidden;
        background-color: rgba($color: $primary, $alpha: 0.1);
        border: 1px solid $primary;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        .card_img {
          position: relative;
          padding: 1rem;
          height: 212px;
          text-align: center;
          @include flexCenter;
          img {
            max-height: 90%;
            min-height: 60%;
            object-fit: cover;
          }
          .sub_bundle_badge {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: #0e2b3a;
            color: #fff;
            padding: 10px;
            border-radius: 10px;
            font-size: 12px;
            z-index: 1;
          }
        }
        .card_img_overlay {
          height: 100%;
          width: 100%;
          position: relative;
          text-align: center;
          cursor: pointer;
          @include flexCenter;
          &.showOverlay {
            img {
              background-color: rgba($black, 0.6);
              filter: brightness(0.4);
            }
          }
          h4 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            z-index: 2;
          }
        }

        .card_content {
          flex-grow: 1;
          text-align: center;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          .title {
            flex-grow: 1;
            padding: 1rem;
            border-bottom: 1px solid $primary;
          }
          .price {
            padding: 1rem;
            h5 {
              font-weight: 800;
            }
          }
          button {
            width: 70%;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  figure {
    width: fit-content;
    margin: auto;
    position: relative;
    // &::after {
    //   content: "+4";
    //   width: 100%;
    //   position: absolute;
    //   inset: 0;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background: rgba(0, 0, 0, 0.6);
    //   font-weight: 800;
    //   font-size: 32px;
    //   color: #ffffff;
    // }
  }
}

.custom_bundleDetailAccordion {
  .bundleDetail_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 769px) {
      flex-direction: column;
    }
    .bundleDetail_lg {
      // width: 50%;
      flex-basis: 100%;
      margin: 20px 0px;
      @media (max-width: 769px) {
        width: 100%;
      }

      .bundleDetail_heading {
        display: flex;
        align-items: center;
        p {
          margin-left: 10px;
        }
        .accordionTitle {
          font-weight: 800;
          font-family: "Roboto";
        }
        .imgWrap {
          height: 60px;
          width: 60px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-height: 60px;
            max-width: 60px;
            object-fit: cover;
          }
        }
      }
      .bundleDetail_cardlist {
        margin: 30px 0px;
        h5 {
          font-weight: 800;
          font-size: 16px;
          color: #5f594c;
          margin: 0px 0px 20px 0px;
        }
        li {
          font-size: 16px;
          color: #5f594c;
          margin: 10px 0px;
        }
        button {
          background: #e9e9e9;
          border-radius: 8px;
          width: 160px;
          height: 44px;
          border: 0px;
          color: $primary;
          font-weight: 500;
          font-size: 16px;
          margin: 28px 0px 10px;
        }
        .htmlWrap {
          ul {
            list-style-position: inside;
          }
        }
      }
    }
  }
  .sub_bundle_wrapper {
    width: 90%;
    margin: auto;
  }
}
.bannerSubText {
  font-size: 20px;
}
.bundleDetail_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
  }
  .bundleDetail_cardContent {
    width: 50%;
    margin: 20px 0px 0px;
    @media (max-width: 769px) {
      width: 100%;
    }
    fieldset {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      border: 1px solid $primary;
      height: 50px;
      &:hover {
        border-color: none;
      }
    }
    .css-14l0706 {
      background-color: red !important;
    }
    p {
      margin-bottom: 10px;
    }
  }
}

.bundleDetail_checkbox {
  display: block;
  position: relative;
  // padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bundleDetail_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.bundleDetail_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
  border-bottom-right-radius: 14px;
}

.bundleDetail_checkbox input:checked ~ .bundleDetail_checkmark {
  background: $green;
  border-radius: 5px;
  border-bottom-right-radius: 10px;
}

.bundleDetail_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.bundleDetail_checkbox input:checked ~ .bundleDetail_checkmark:after {
  display: block;
}
.bundleDetail_checkbox .bundleDetail_checkmark:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.acoContainer {
  background-color: #fff;
  box-shadow: none !important;
  border: 1px solid $primary;
  border-radius: 8px;
}
.bundleDetail {
  :global(.Mui-expanded.containerAccordion) {
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08) !important;
    border: none;
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: right;
  display: block;
}

.productDetailCard {
  background: #ffffff;
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 30px 15px;
  margin: 50px 0px;
  @include max($md) {
    margin: 25px 0px;
  }
  .imgWrap {
    background: #ffffff;
    border: 1px solid $primary;
    border-radius: 4px;
    margin-bottom: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px solid black;
    }
    img {
      max-height: 50px;
      max-width: 40px;
    }
  }

  .custom_img {
    width: 100%;
    max-width: 58px;
    cursor: pointer;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    @include max($md) {
      display: none !important;
    }
  }
  .imgBox {
    max-width: 360px !important;
    min-width: 360px !important;
    max-height: 360px;
    min-height: 360px;
    overflow: hidden;
    border: 1px solid $primary;
    border-radius: 8px;
    margin-right: 50px !important;
    margin-left: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    @include max($md) {
      display: none !important;
    }
  }
  .Productbig {
    max-width: 320px !important;
    border-radius: 8px;
    max-height: 320px !important;
    background: #ffffff;

    // margin-left: 10px;
    @media (max-width: 1200px) {
      margin-left: 0px;
    }
  }
  .customSelectBox {
    gap: 10px;
    input {
      border: 1px solid $primary;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      height: 44px;
      padding: 1px 80px 0px 10px;
    }
  }
}
.custom_filed {
  position: relative;
  width: 300px;
  @include max($md) {
    width: 360px !important;
  }
  @media (max-width: 424px) {
    width: 310px !important;
  }
  fieldset {
    border: 0px;
  }
  label {
    background-color: #fff;
    padding: 0px;
  }
}
.bundle_custom_filed {
  position: relative;
}
.productItemCard {
  @media (max-width: 1200px) {
    margin-left: 20px;
  }
  @media (max-width: 600px) {
    margin-left: 0px;
  }

  .cartQty {
    color: $primary;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: -0.2px;
    margin-bottom: 20px;
  }

  h5 {
    font-weight: 800;
    font-size: 20px;
    color: $primary;
    @include max($md) {
      margin-top: 8px;
    }
  }
  h3 {
    color: $primary;
    font-weight: 800;
    font-size: 32px;
    margin: 20px 0px;
  }
  .itembtn {
    margin-bottom: 15px;
    .single {
      border: 1px solid $primary;
      border-radius: 6px;
      background-color: transparent;
      width: 120px;
      height: 40px;
      font-weight: 400;
      font-size: 16px;
      color: $primary;
    }
    .multiple {
      font-weight: 400;
      font-size: 16px;
      color: $primary;
      background-color: transparent;
      border: 0px;
      margin-left: 20px;
    }
  }
  .addToCartWrap {
    :global {
      .MuiButton-root {
        border-radius: 8px;
        width: 156px;
        height: 44px;
        border: 0px;
        margin: 30px 0px 20px 5px;
      }
    }
  }
}
.form_cantrol {
  border: 1px solid $primary;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  width: 500px;
  min-width: 500px;
  label {
    color: $primary;
    font-weight: 500;
    font-size: 14px;
    margin: 10px 0px 7px 0px;
  }
}
.loseHigh {
  position: absolute;
  top: 32px;
  right: 5px;
  display: flex;
  @media (max-width: 768px) {
    width: 21ch !important;
    right: -95px;
  }
  button {
    background: #e9e9e9;
    border: 0px;
    width: 28px;
    height: 28px;
  }
  .lose {
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    border-radius: 4px;
    margin-right: 3px;
  }
  .High {
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border-radius: 4px;
  }
}
.productDetailList {
  padding: 50px 0px;
  @media (max-width: 992px) {
    padding: 10px 0px;
  }
  h4 {
    font-weight: 800;
    font-size: 20px;
    color: $primary;
    margin: 20px 0px;
    @include max($md) {
      text-align: center;
    }
  }
  .listContent {
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    p {
      font-weight: 500;
      font-size: 18px;
      color: $primary;
      margin-left: 10px;
      @media (max-width: 500px) {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

// Accordian detail styles

.detailWrap {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .imgWrap {
    height: 400px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    border-radius: 10px;
    margin: 10px 40px 10px 10px;
    flex-shrink: 0;
    @include max($sm) {
      height: 280px;
      width: 280px;
      margin: auto;
    }
    img {
      border-radius: 10px;
      max-height: 350px;
      max-width: 350px;
      object-fit: cover;
      @include max($sm) {
        max-height: 270px;
        max-width: 270px;
        margin: auto;
      }
    }
  }
  .contentWrap {
    flex-grow: 1;
    @media (max-width: 768px) {
      padding-top: 25px;
    }
  }
}

.bundleName {
  padding: 0 24px;
  @include max($sm) {
    text-align: center;
  }
  .info {
    font-size: 14px;
    color: #0e2b3ac2;
  }
}

.productDetail_mob_view {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  overflow: hidden;
  .custom_img_mob_view {
    width: 100%;
    max-width: 371px;
    cursor: pointer;
    height: 400px;
    gap: 11px;
    display: flex;
    overflow: auto !important;
    margin-bottom: 5px;
    // overflow-y: auto;
    // overflow-x: hidden;
  }
  .imgBox_mob_view {
    max-width: 360px !important;
    min-width: 360px !important;
    max-height: 360px;
    min-height: 360px;
    overflow: hidden;
    border: 1px solid $primary;
    border-radius: 8px;
    // margin: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 424px) {
      min-width: 310px !important;
    }
  }
  @include min($md) {
    display: none !important;
  }
}
