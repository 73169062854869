@import "../var";

.multiSelectList_item {
  display: flex;
  align-items: center;
  margin: 10px;
  gap: 10px;
  .content {
    padding: 2px 4px;
    height: 1.5rem;
    border-radius: 4px;
    border: 1px solid $primary;
    flex-grow: 1;
    font-size: var(--f14);
  }
  .close {
    cursor: pointer;
    border: 1px solid $primary;
    border-radius: 4px;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 1.3em;
    }
  }
  //   &:hover {
  //     background-color: $secondary;
  //   }
}
.field_error {
  top: calc(100% + 2px);
  right: 4px;
  color: #d32f2f;
  font-size: 12px;
  font-weight: 400;
}

.multiEmailSelect {
  input {
    margin-right: 0 !important;
  }
}
