@import "../var";

.CheckoutCart {
  margin-top: 30px;
}
.cursor {
  cursor: pointer;
}

.qtyDiv {
  background: #ffffff;
  border: 1px solid $primary;
  border-radius: 6px;
  color: $primary;
  font-weight: 400;
  font-size: 16px;
  width: 200px;
  height: 30px;
  cursor: auto;
}

.stripeInput {
  background-color: #fff;
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 10px;
  max-height: 48px;
}
.CheckoutCartOrderSummary {
  background: #ffffff;
  border: 1px solid $primary;
  border-radius: 8px;

  .CheckoutCartOrderSummaryHeading {
    background: rgba($btn_bg, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: 10px;
    height: 30px;
    font-size: 14px;
  }
  .CheckoutCartOrderSummaryCoupon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: auto;
    background: #f9f9f9;
    border-radius: 8px;
  }
}
.priceWrapper {
  margin-top: 40px;
  font-size: 16px;
  .singlePrice {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > * {
      flex-basis: 50%;
    }
    .right {
      text-align: right;
    }
  }
}
.orderButton {
  text-align: center;
  line-height: 18.75px;
  margin: 42px;
}
.couponWrapper {
  box-sizing: border-box;
  background: rgba($btn_bg, 0.1);
  border-radius: 8px;
  margin: 25px 10px;
  padding: 15px 10px 20px;
  font-size: 16px;
  align-items: end;
  width: unset !important;
  display: flex;
  justify-content: space-between;
  .couponExpired {
    color: red;
    padding-top: 20px;
    margin: 5px;
  }
  .couponBtn {
    button {
      width: 100%;
    }
  }
}
.fullCard {
  .summaryCard {
    width: 116px;
    height: 116px;
    margin: 10px;
    background: #f9f9f9;
    border: 1px solid $primary;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .imgResp {
      margin: 2.13px 3.73px;
      width: 22.53px;
      height: 25.6px;
    }

    .imgRespSingle {
      margin: 2.13px 3.73px;
      width: 102.53px;
      height: 105.6px;
    }
  }
  .shoppingCart_img {
    --space: 2px;
    width: 120px;
    background: #f9f9f9;
    border: 1px solid $primary;
    border-radius: 6px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: calc(2 * var(--space)) 0;
    margin: calc(-1 * var(--space));
    margin: 10px;
    // margin-top: 20px;
    height: 120px;
    max-height: 120px;
    min-height: 120px;
    .img_wrap {
      padding: 0 var(--space);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;
      img {
        border-radius: 6px;
      }
      &.multiImage {
        flex-basis: 50%;
        position: relative;
        .layer {
          position: absolute;
          left: var(--space);
          width: calc(100% - (2 * var(--space)));
          height: 100%;
          background-color: rgba($black, 0.6);
          color: $white;
          @include flexCenter;
          img {
            filter: brightness(0.5);
          }
        }
        img {
          // object-fit: cover;
          max-height: 44px;
          max-width: 44px;
          // width: 100%;
          // object-position: top;
        }
      }
    }
    img {
      max-width: 100px;
      max-height: 100px;
    }
    @include max($lg) {
      width: 150px;
      // img {
      //   height: 20px;
      // }
    }
    @include max($md) {
      margin-top: 20px;
    }
  }
}

.heightCard {
  // border: 2px solid red;
  height: 128px;
  @media screen and (max-width: 620px) {
    height: 105px;
  }

  .heightCardInner {
    margin: 10px;
  }

  .heightCardInner1 {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.checkoutBtn {
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    gap: 10px;
  }
  .empty {
    background: #ffffff;
    border: 1px solid $primary;
    border-radius: 8px;
    width: 167px;
    height: 44px;
    color: $primary;
    font-weight: 500;
    font-size: 16px;
    margin: 30px 0px;
  }
  .continue {
    background: #e9e9e9 !important;
    border-radius: 8px;
    color: $primary;
    font-weight: 500;
    font-size: 16px;
    width: 230px;
    height: 44px;
    // border: 1px solid $primary;
    margin: 20px 0px;
    text-transform: capitalize;
  }
}
.checkField_wrapper {
  display: flex;
  :global {
    .static_input {
      flex-grow: 1;
    }
  }
}

.deliveryFlow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: row;

  text-align: center;
  .deliveryFlowcircle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    padding: 2px;
  }
  .innerFlowCircle {
    background: rgba($btn_bg, 0.5);
    width: 34px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 15px;
      background-color: $primary;
      bottom: -18px;
    }
  }

  .deliveryFlowInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    flex: 1;
    margin: 20px 0px;
    @media screen and (max-width: 620px) {
      margin: 10px 0px;
    }
    .deliveryFlowName {
      margin: 32px 0px;
      font-weight: 500;
      font-size: 16px;
      color: $primary;
      @media (max-width: 540px) {
        font-size: 12px;
        font-weight: 400;
      }
    }
    &::after {
      content: "";
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      left: -50%;
      top: 50px;
    }
    &:nth-child(1) {
      &::after {
        content: unset;
      }
    }
  }
}
.flowName {
  margin-bottom: 22px;
  font-size: 40px;
  font-weight: 500;
  @include max($sm) {
    font-size: 32px;
  }
}
.flowCheckmark {
  position: absolute;
  top: 7px;
  right: -15px;
  width: 20px;
  height: 20px;
}

.appliedCoupon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  .couponCode {
    color: $primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .discountPrice {
    color: $primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .removeCoupon {
    button {
      color: red;
      border: none;
      background-color: transparent !important;
      text-transform: capitalize;
    }
  }
}
.loaderColor {
  svg {
    color: #fff;
  }
}
