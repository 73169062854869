@import "../var";

// ============= team section =================
.team_section {
  padding: var(--sectionPadding) 0;
  .team_box {
    padding: 1.75rem 3.45rem 3.45rem;
    border-radius: 8px;
    background-color: $secondary;
    @include max($md) {
      padding: 1.5rem;
    }
    .teamForm {
      display: flex;
      align-items: flex-end;
      gap: 1.875rem;
      margin-bottom: 1.5rem;
      :global {
        .form_control {
          flex-grow: 1;
        }
      }
      button {
        min-height: 46px;
        margin: 0 auto;
        @include min($md) {
          min-width: 220px;
        }
      }
      @include max($md) {
        flex-wrap: wrap;
        gap: 1rem;
        :global {
          .form_control {
            flex-basis: 100%;
          }
        }
      }
    }
  }
}

// ======== shop optional section ==========
.shopOptional_section {
  padding: var(--sectionPadding) 0;
  display: flex;
  align-items: center;
  .wrapper {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    background-color: $secondary;
    .image {
      flex-basis: 50%;
      max-width: 50%;
      padding-bottom: 53.0625%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .right_content {
      flex-basis: 50%;
      max-width: 50%;
      padding: 1.875rem;
    }
    .content_box {
      height: 100%;
      border: 1px solid $primary;
      @include flexCenter;
      padding: 1rem;
    }
    .content {
      text-align: center;
      max-width: 370px;
      .heading {
        margin-bottom: 3rem;
        p {
          margin-top: 1rem;
          word-wrap: break-word;
        }
      }
    }
    @include max($md) {
      flex-wrap: wrap;
      .image,
      .right_content {
        max-width: 100%;
        flex-basis: 100%;
      }
      .image {
        padding-bottom: 88.4625%;
      }
      .right_content {
        padding: 0;
      }
      .content_box {
        border: none;
      }
      .content {
        .heading {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
.note {
  word-wrap: break-word;
}
.teamCustomForm {
  display: flex;
  align-items: flex-end;
  position: relative;
  gap: 1.875rem;
  margin-bottom: 1.5rem;
  :global {
    .form_control {
      flex-grow: 1;
    }
  }
}
