@import "./var";
@import "./common";

.heading {
  text-align: center;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  &.left {
    text-align: left;
  }
  h2 {
    font-size: var(--f36);
    font-weight: 500;
    word-wrap: break-word;
  }
  h4 {
    font-weight: 400;
    margin-top: 1rem;
    word-wrap: break-word;
  }
  h5 {
    font-size: var(--f20);
    font-weight: 400;
    margin-top: 4px;
    word-wrap: break-word;
  }
}

// ============ image section ============
.imageBox_section {
  padding: var(--sectionPadding) 0;
  --bgHeight: 570px;
  --imageSpace: 80px;
  @include max($lg) {
    --bgHeight: 360px;
    --imageSpace: 40px;
  }
  .bg_image {
    padding: var(--imageSpace) 1rem;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: var(--bgHeight);
    background-color: red;
    @include flexCenter;
  }
  .whiteBox {
    max-width: 1020px;
    width: 90%;
    background-color: rgba($white, 0.95);
    padding: 20px;
    border-radius: inherit;
    .whiteBox_border {
      padding: inherit;
      min-height: calc(var(--bgHeight) - (2.5 * var(--imageSpace)));
      border: 1px solid $primary;
      border-radius: inherit;
      @include flexCenter;
    }
    .heading {
      margin-bottom: 0;
      width: 80%;
      p {
        margin-top: 1rem;
        line-height: 1.6;
      }
    }
    @include max($md) {
      width: 95%;
      padding: 12px;
      .heading {
        width: 100%;
      }
    }
  }
}
.loadingBars {
  display: flex;
  justify-content: center;
  color: inherit;
}
.loadingBarsScreen {
  display: flex;
  justify-content: center;
  color: inherit;
  margin-top: 250px;
}
.loadingScreen {
  display: flex;
  justify-content: center;
  color: inherit;
  // margin-top: 250px;
}
.fallback {
  display: block;
  border: 0.063rem solid #ce3446;
  padding: 1rem;
  color: #ce3446;
  border-radius: 0.313rem;

  &__title {
    padding: 0;
    margin: 0;
    color: #ce3446;
  }
  &__body {
    padding: 0;
    margin: 0;
    color: #ce3446;
    p {
      strong {
        color: #ce3446;
      }
    }
  }
}
